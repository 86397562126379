import { generateClient } from "aws-amplify/api";
import gql from "graphql-tag";

const client = generateClient();

const getMyProfile = async () => {
  const result = await client.graphql({
    query: gql`
      query getMyProfile {
        getMyProfile {
          id
          name
          screenName
          imageUrl
          backgroundImageUrl
          bio
          location
          website
          birthdate
          createdAt
          followersCount
          followingCount
          tweetsCount
          likesCounts
          isBot
        }
      }
    `,
  });
  const profile = result.data.getMyProfile;

  profile.imageUrl = profile.imageUrl || "default_profile.png";
  return profile;
};

const getProfileByScreenName = async (screenName) => {
  const result = await client.graphql({
    query: gql`
      query getProfile($screenName: String!) {
        getProfile(screenName: $screenName) {
          id
          name
          screenName
          imageUrl
          backgroundImageUrl
          bio
          location
          website
          birthdate
          createdAt
          followersCount
          followingCount
          tweetsCount
          likesCounts
          following
          followedBy
          isBot
        }
      }
    `,
    variables: {
      screenName,
    },
  });
  const profile = result.data.getProfile || {};

  profile.imageUrl = profile.imageUrl || "default_profile.png";
  return profile;
};

const getMyTimeline = async (limit, nextToken) => {
  const result = await client.graphql({
    query: gql`
      query getMyTimeline($limit: Int!, $nextToken: String) {
        getMyTimeline(limit: $limit, nextToken: $nextToken) {
          nextToken
          tweets {
            __typename
            id
            profile {
              id
              name
              screenName
              imageUrl
            }
            createdAt
            ... on Tweet {
              text
              liked
              likes
              retweeted
              retweets
              replies
            }
            ... on Retweet {
              retweetOf {
                id
                profile {
                  id
                  name
                  screenName
                  imageUrl
                }
                createdAt
                ... on Tweet {
                  text
                  liked
                  likes
                  retweeted
                  retweets
                  replies
                }
                ... on Reply {
                  text
                  liked
                  likes
                  retweeted
                  retweets
                  replies
                }
              }
            }
            ... on Reply {
              text
              liked
              likes
              retweeted
              retweets
              replies
              inReplyToTweet {
                id
                profile {
                  id
                  name
                  screenName
                  imageUrl
                }
                createdAt
                ... on Tweet {
                  text
                  liked
                  likes
                  retweeted
                  retweets
                  replies
                }
                ... on Reply {
                  text
                  liked
                  likes
                  retweeted
                  retweets
                  replies
                }
                ... on Retweet {
                  retweetOf {
                    id
                  }
                }
              }
              inReplyToUsers {
                id
                name
                screenName
                imageUrl
              }
            }
          }
        }
      }
    `,
    variables: {
      limit,
      nextToken,
    },
  });

  return result.data.getMyTimeline;
};

const getTweets = async (userId, limit, nextToken) => {
  const result = await client.graphql({
    query: gql`
      query getTweets($userId: ID!, $limit: Int!, $nextToken: String) {
        getTweets(userId: $userId, limit: $limit, nextToken: $nextToken) {
          nextToken
          tweets {
            ... on Tweet {
              id
              createdAt
              text
              liked
              likes
              retweeted
              retweets
              replies
              profile {
                id
                name
                screenName
                imageUrl
              }
            }
            ... on Reply {
              text
              liked
              likes
              retweeted
              retweets
              replies
              inReplyToTweet {
                id
                profile {
                  id
                  name
                  screenName
                  imageUrl
                }
                createdAt
                ... on Tweet {
                  text
                  liked
                  likes
                  retweeted
                  retweets
                  replies
                }
                ... on Reply {
                  text
                  liked
                  likes
                  retweeted
                  retweets
                  replies
                }
              }
              inReplyToUsers {
                id
                name
                screenName
                imageUrl
              }
            }
          }
        }
      }
    `,
    variables: {
      userId,
      limit,
      nextToken,
    },
  });

  return result.data.getTweets;
};

const getImageUploadUrl = async (extension, contentType, folder, key) => {
  const result = await client.graphql({
    query: gql`
      query getImageUploadUrl(
        $extension: String
        $contentType: String
        $folder: String
        $key: String
      ) {
        getImageUploadUrl(
          extension: $extension
          contentType: $contentType
          folder: $folder
          key: $key
        ) {
          signedUrl
          url
        }
      }
    `,
    variables: {
      extension,
      contentType,
      folder,
      key,
    },
  });

  return result.data.getImageUploadUrl;
};

const editMyProfile = async (input) => {
  const result = await client.graphql({
    query: gql`
      mutation editMyProfile($input: ProfileInput!) {
        editMyProfile(input: $input) {
          backgroundImageUrl
          bio
          createdAt
          birthdate
          followersCount
          followingCount
          id
          imageUrl
          likesCounts
          location
          name
          screenName
          tweetsCount
          website
        }
      }
    `,
    variables: {
      input,
    },
  });
  return result.data.editMyProfile;
};

const tweet = async (text) => {
  const result = await client.graphql({
    query: gql`
      mutation tweet($text: String!) {
        tweet(text: $text) {
          createdAt
          id
          liked
          likes
          profile {
            imageUrl
            name
            screenName
          }
          replies
          retweeted
          retweets
          text
        }
      }
    `,
    variables: {
      text,
    },
  });
  return result.data.tweet;
};

const retweet = async (tweetId) => {
  await client.graphql({
    query: gql`
      mutation retweet($tweetId: ID!) {
        retweet(tweetId: $tweetId) {
          id
          createdAt
        }
      }
    `,
    variables: {
      tweetId,
    },
  });
};

const unretweet = async (tweetId) => {
  await client.graphql({
    query: gql`
      mutation unretweet($tweetId: ID!) {
        unretweet(tweetId: $tweetId)
      }
    `,
    variables: {
      tweetId,
    },
  });
};

const like = async (tweetId) => {
  await client.graphql({
    query: gql`
      mutation like($tweetId: ID!) {
        like(tweetId: $tweetId)
      }
    `,
    variables: {
      tweetId,
    },
  });
};

const unlike = async (tweetId) => {
  await client.graphql({
    query: gql`
      mutation unlike($tweetId: ID!) {
        unlike(tweetId: $tweetId)
      }
    `,
    variables: {
      tweetId,
    },
  });
};

const reply = async (tweetId, text) => {
  const result = await client.graphql({
    query: gql`
      mutation reply($tweetId: ID!, $text: String!) {
        reply(tweetId: $tweetId, text: $text) {
          id
          createdAt
          liked
          likes
          profile {
            imageUrl
            name
            screenName
          }
          replies
          retweeted
          retweets
          text
        }
      }
    `,
    variables: {
      tweetId,
      text,
    },
  });

  return result.data.reply;
};

const follow = async (userId) => {
  await client.graphql({
    query: gql`
      mutation follow($userId: ID!) {
        follow(userId: $userId)
      }
    `,
    variables: {
      userId,
    },
  });
};

const unfollow = async (userId) => {
  await client.graphql({
    query: gql`
      mutation unfollow($userId: ID!) {
        unfollow(userId: $userId)
      }
    `,
    variables: {
      userId,
    },
  });
};

const getFollowers = async (userId, limit = 10) => {
  const result = await client.graphql({
    query: gql`
      query getFollowers($userId: ID!, $limit: Int!) {
        getFollowers(userId: $userId, limit: $limit) {
          profiles {
            id
            name
            screenName
            imageUrl
            bio
            ... on OtherProfile {
              following
              followedBy
            }
          }
          nextToken
        }
      }
    `,
    variables: {
      userId,
      limit,
    },
  });

  return result.data.getFollowers;
};

const getFollowing = async (userId, limit = 10) => {
  const result = await client.graphql({
    query: gql`
      query getFollowing($userId: ID!, $limit: Int!) {
        getFollowing(userId: $userId, limit: $limit) {
          profiles {
            id
            name
            screenName
            imageUrl
            bio
            ... on OtherProfile {
              following
              followedBy
            }
          }
          nextToken
        }
      }
    `,
    variables: {
      userId,
      limit,
    },
  });

  return result.data.getFollowing;
};

const search = async (query, mode, limit, nextToken) => {
  const result = await client.graphql({
    query: gql`
      query search(
        $query: String!
        $mode: SearchMode!
        $limit: Int!
        $nextToken: String
      ) {
        search(
          query: $query
          mode: $mode
          limit: $limit
          nextToken: $nextToken
        ) {
          nextToken
          results {
            __typename
            ... on Tweet {
              id
              createdAt
              text
              liked
              likes
              retweeted
              retweets
              replies
              profile {
                id
                name
                screenName
                imageUrl
              }
            }
            ... on Reply {
              id
              text
              liked
              likes
              retweeted
              retweets
              replies
              profile {
                id
                name
                screenName
                imageUrl
              }
              inReplyToTweet {
                id
                profile {
                  id
                  name
                  screenName
                  imageUrl
                }
                createdAt
                ... on Tweet {
                  text
                  liked
                  likes
                  retweeted
                  retweets
                  replies
                }
                ... on Reply {
                  text
                  liked
                  likes
                  retweeted
                  retweets
                  replies
                }
              }
              inReplyToUsers {
                id
                name
                screenName
                imageUrl
              }
            }
            ... on OtherProfile {
              id
              name
              screenName
              imageUrl
              bio
              following
              followedBy
              isBot
            }
            ... on MyProfile {
              id
              name
              screenName
              imageUrl
              bio
              isBot
            }
          }
        }
      }
    `,
    variables: {
      query,
      mode,
      limit,
      nextToken,
    },
  });

  return result.data.search;
};

const getHashTag = async (hashTag, mode, limit, nextToken) => {
  const result = await client.graphql({
    query: gql`
      query getHashTag(
        $hashTag: String!
        $mode: HashTagMode!
        $limit: Int!
        $nextToken: String
      ) {
        getHashTag(
          hashTag: $hashTag
          mode: $mode
          limit: $limit
          nextToken: $nextToken
        ) {
          nextToken
          results {
            __typename
            ... on Tweet {
              id
              createdAt
              text
              liked
              likes
              retweeted
              retweets
              replies
              profile {
                id
                name
                screenName
                imageUrl
              }
            }
            ... on Reply {
              id
              text
              liked
              likes
              retweeted
              retweets
              replies
              profile {
                id
                name
                screenName
                imageUrl
              }
              inReplyToTweet {
                id
                profile {
                  id
                  name
                  screenName
                  imageUrl
                }
                createdAt
                ... on Tweet {
                  text
                  liked
                  likes
                  retweeted
                  retweets
                  replies
                }
                ... on Reply {
                  text
                  liked
                  likes
                  retweeted
                  retweets
                  replies
                }
              }
              inReplyToUsers {
                id
                name
                screenName
                imageUrl
              }
            }
            ... on OtherProfile {
              id
              name
              screenName
              imageUrl
              bio
              following
              followedBy
            }
            ... on MyProfile {
              id
              name
              screenName
              imageUrl
              bio
            }
          }
        }
      }
    `,
    variables: {
      hashTag,
      mode,
      limit,
      nextToken,
    },
  });

  return result.data.getHashTag;
};

const getOnNotifiedSubscription = (userId) => {
  const onNotified = {
    query: gql`
      subscription onNotified($userId: ID!) {
        onNotified(userId: $userId) {
          ... on Retweeted {
            id
            createdAt
            retweetedBy
            tweetId
            retweetId
            type
          }
          ... on Liked {
            id
            createdAt
            likedBy
            tweetId
            type
          }
          ... on Mentioned {
            id
            createdAt
            mentionedBy
            mentionedByTweetId
            type
          }
          ... on Replied {
            id
            createdAt
            repliedBy
            tweetId
            replyTweetId
            type
          }
          ... on DMed {
            id
            message
            createdAt
            otherUserId
            type
          }
        }
      }
    `,
    variables: {
      userId: userId,
    },
  };

  return client.graphql(onNotified);
};

const listConversations = async (limit, nextToken) => {
  const result = await client.graphql({
    query: gql`
      query listConversations($limit: Int!, $nextToken: String) {
        listConversations(limit: $limit, nextToken: $nextToken) {
          conversations {
            id
            otherUser {
              id
              name
              screenName
              imageUrl
              backgroundImageUrl
              bio
              location
              website
              birthdate
              createdAt
              followersCount
              followingCount
              tweetsCount
              likesCounts
              following
              followedBy
            }
            lastMessage
            lastModified
          }
          nextToken
        }
      }
    `,
    variables: {
      limit,
      nextToken,
    },
  });

  return result.data.listConversations;
};

const getDirectMessages = async (otherUserId, limit, nextToken) => {
  const result = await client.graphql({
    query: gql`
      query getDirectMessages(
        $otherUserId: ID!
        $limit: Int!
        $nextToken: String
      ) {
        getDirectMessages(
          otherUserId: $otherUserId
          limit: $limit
          nextToken: $nextToken
        ) {
          messages {
            messageId
            message
            timestamp
            hasAnswer
            from {
              imageUrl
              screenName
            }
            documents {
              documents {
                id
                awsStorageUrl
                documentName
                physicalPageNumber
                contentPageNumber
                sourceType
              }
            }
          }
          nextToken
        }
      }
    `,
    variables: {
      otherUserId,
      limit,
      nextToken,
    },
  });

  if (result.data.getDirectMessages.messages.length > 0) {
    result.data.getDirectMessages.messages.forEach((dm) => {
      if (
        dm.documents.documents.length > 0
      ) {
        dm.documents.documents = dm.documents.documents.sort(
          (a, b) => a.physicalPageNumber - b.physicalPageNumber
        );
      }
    });
  }

  return result.data.getDirectMessages;
};

const sendDirectMessage = async (message, otherUserId) => {
  const result = await client.graphql({
    query: gql`
      mutation sendDirectMessage($message: String!, $otherUserId: ID!) {
        sendDirectMessage(message: $message, otherUserId: $otherUserId) {
          id
          message: lastMessage
          lastModified
          otherUser {
            name
            screenName
            imageUrl
          }
        }
      }
    `,
    variables: {
      message,
      otherUserId,
    },
  });

  return result.data.sendDirectMessage;
};

export {
  getMyProfile,
  getProfileByScreenName,
  getMyTimeline,
  getTweets,
  getImageUploadUrl,
  editMyProfile,
  tweet,
  retweet,
  unretweet,
  like,
  unlike,
  reply,
  follow,
  unfollow,
  getFollowers,
  getFollowing,
  search,
  getHashTag,
  getOnNotifiedSubscription,
  listConversations,
  getDirectMessages,
  sendDirectMessage,
};
