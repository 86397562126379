/* eslint-disable no-console */

if ("serviceWorker" in navigator) {
  console.log("Service Worker is supported, registering service worker");
  console.log(`env: ${process.env.NODE_ENV}`);
  navigator.serviceWorker.register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated() {
      console.log("New content is available; please refresh.");
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  }).then((registration) => {
    setInterval(() => {
      console.log(`updating service worker registration`);
      registration.update();
    }, 60000); // Check every minute
  });
} else {
  console.error("Service workers are not supported.");
}
