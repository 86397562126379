import {
  signIn,
  signUp,
  getCurrentUser,
  confirmSignUp,
  resendSignUpCode,
  fetchUserAttributes,
  signOut,
} from "aws-amplify/auth";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
const {
  fromCognitoIdentityPool,
} = require("@aws-sdk/credential-provider-cognito-identity");

import { FirehoseClient, PutRecordCommand } from "@aws-sdk/client-firehose";
import router from "../../../router";

export default {
  loginUser({ commit }, user) {
    commit("USER_LOGIN", user);
  },

  async logoutUser({ commit, dispatch }) {
    await signOut({
      global: true,
    });
    commit("USER_LOGOUT");
    dispatch("signup/setSignupStep", "", { root: true });
    dispatch("twitter/unsubscribeNotifications", null, { root: true });
    dispatch("twitter/resetState", null, { root: true });
    dispatch("profilePage/resetState", null, { root: true });
    router.push("/");
  },

  async signUp({ commit }, form) {
    const user = await signUp({
      username: form.username,
      password: form.password,
      options: {
        userAttributes: {
          name: form.name,
        },
      },
    });
    commit("USER_SIGNUP", user);
  },

  async confirmSignUp(_, form) {
    await confirmSignUp({
      username: form.email,
      confirmationCode: form.verificationCode,
    });
  },

  async resendSignUp(_, form) {
    await resendSignUpCode({
      username: form.email,
    });
  },

  async signInUser({ dispatch }, form) {
    const user = {
      ...(await signIn({
        username: form.email,
        password: form.password,
      })),
      ...fetchUserAttributes(),
    };

    await dispatch("loginUser", user);
    await dispatch("twitter/setProfile", null, { root: true });
    await dispatch("twitter/subscribeNotifications", null, { root: true });
    router.push({ name: "Home" });
  },

  async loginUserIfAlreadyAuthenticated({ dispatch }) {
    try {
      const user = {
        ...(await getCurrentUser()),
        ...(await fetchUserAttributes()),
      };
  
      if (user) {
        console.log("user is logged in already");
        await dispatch("loginUser", user);
        await dispatch("twitter/setProfile", null, { root: true });
        await dispatch("twitter/subscribeNotifications", null, { root: true });
      }
    } catch (error) {
      console.error("login failed, signing out...", error);
      this.signOut(); 
    }
  },

  async trackEvent(_, event) {
    try {
      const cognitoidentity = new CognitoIdentityClient({
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({
            region: process.env.VUE_APP_COGNITO_REGION,
          }),
          identityPoolId: process.env.VUE_APP_AUTH_IDENTITY_POOL_ID,
        }),
      });
      const credentials = await cognitoidentity.config.credentials();

      const firehoseClient = new FirehoseClient({
        region: process.env.VUE_APP_COGNITO_REGION,
        credentials,
      });

      await firehoseClient.send(
        new PutRecordCommand({
          DeliveryStreamName:
            process.env.VUE_APP_AWS_KINESIS_FIREHOSE_DELIVERY_STREAM_NAME,
          Record: {
            Data: new TextEncoder().encode(JSON.stringify(event), "base64"),
          },
        })
      );
    } catch (error) {
      console.error("firehose tracker failed", error);
    }
  },
};
