import { createApp } from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";
import { Amplify } from "aws-amplify";
import router from "./router";
import store from "./store";
import { directives, globalFilters } from "./plugins";
import './registerServiceWorker';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.VUE_APP_COGNITO_USERPOOLID,
      userPoolClientId: process.env.VUE_APP_COGNITO_CLIENTID,
    },
  },
  API: {
    GraphQL: {
      endpoint: process.env.VUE_APP_API_URL,
      region: process.env.VUE_APP_COGNITO_REGION,
      defaultAuthMode: "userPool",
    },
  },
});

createApp(App)
  .use(directives)
  .use(globalFilters)
  .use(router)
  .use(store)
  .mount("#app");
