import { getCurrentUser } from "aws-amplify/auth";

export default async (to, from, next) => {
  const isProtected = to.matched.some((route) => route.meta.protected);
  document.title = to.meta.title;
  let loggedIn = true;
  try {
    await getCurrentUser();
  } catch (error) {
    loggedIn = false;
  }

  if (isProtected && !loggedIn) {
    next("/");
    return;
  }

  if (to.path === '/' && loggedIn) {
    next("/Home");
    return;
  }

  next();
};
