import { createRouter, createWebHashHistory } from "vue-router";
import Root from "../views/Root.vue";
import AuthMiddleWare from "./auth.guard";

const routes = [
  {
    path: "/",
    name: "Root",
    component: Root,
    meta: { title: "Vitamins Central"}
  },
  {
    path: "/login",
    name: "LogIn",
    meta: { title: "Login"},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "logIn" */ "../views/LogIn.vue"),
  },
  {
    path: "/home",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: {protected: true, title: "Vitamins Central"}
  },
  {
    path: '/search',
    name: 'Search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
    meta: { protected: true, title: "Search" }
  },
  {
    path: '/hashtag',
    name: 'Hashtag',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hashtag" */ '../views/Hashtag.vue'),
    meta: { protected: true, title: "Hashtag" }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "notifications" */ '../views/Notifications.vue'),
    meta: { protected: true, title: "Notifications" }
  },  {
    path: '/messages',
    name: 'Messages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "messages" */ '../views/Messages.vue'),
    props: true,
    meta: { protected: true, title: "Messages" }
  },
  {
    path: '/:screenName',
    name: 'Profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/Profile.vue'),
    meta: { protected: true, title: "Profile" }
  },
  {
    path: '/:screenName/followers',
    name: 'Followers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "followers" */ '../views/Followers.vue'),
    props: true,
    meta: { protected: true, title: "Followers" }
  },
  {
    path: '/:screenName/following',
    name: 'Following',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "following" */ '../views/Following.vue'),
    props: true,
    meta: { protected: true, title: "Following" }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach(AuthMiddleWare);

export default router;
