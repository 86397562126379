import { createStore } from 'vuex';
import authentication from "./modules/authentication";
import signup from "./modules/signup";
import twitter from './modules/twitter';
import profilePage from './modules/twitter';

// Create a new store instance.
const store = createStore({
    modules: {
        authentication,
        signup,
        twitter,
        profilePage
    }
})

export default store;