import escapeDirective from "./escape.directive";
import scrollDirective from "./scroll.directive";
import linkifyDirective from "./linkify.directive";
import timeago from "./timeago"
import time from "./time"

export const directives = {
  install(app) {
    app.directive("escape", escapeDirective);
    app.directive("scroll", scrollDirective);
    app.directive("linkify", linkifyDirective);
  },
};

export const globalFilters = {
  install(app) {
    app.config.globalProperties.$filters = {
      timeago,
      time
    };
    app.config.errorHandler = (err, vm, info) => {
      console.log(`Error: ${JSON.stringify(err)}\nInfo: ${JSON.stringify(info)}`);
    }
  },
};
